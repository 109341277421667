import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
    {
        path: '/:shortLink',
        name: 'home',
        component: HomeView,
        props: true
    },
    {
        path: '/adr',
        name: 'adr',
        component: () => import(/* webpackChunkName: "about" */ '@/views/Address.vue')
    },
    {
        path: '/succ',
        name: 'succ',
        component: () => import(/* webpackChunkName: "about" */ '@/views/result/Succ.vue')
    },
    {
        path: '/ing',
        name: 'ing',
        component: () => import(/* webpackChunkName: "about" */ '@/views/result/Ing.vue')
    },
    {
        path: '/wrong',
        name: 'wrong',
        component: () => import(/* webpackChunkName: "fail" */ '@/views/result/Wrong.vue')
    },
    {
        path: '/expire',
        name: 'expire',
        component: () => import(/* webpackChunkName: "fail" */ '@/views/result/Expire.vue')
    },
    {
        path: '/nocards',
        name: 'nocards',
        component: () => import(/* webpackChunkName: "fail" */ '@/views/result/NoCards.vue')
    },
    {
        path: '/404',
        component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/404.vue')
    },
    {
        path: '/403',
        component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/403.vue')
    },
    {
        path: '/:pathMatch(.*)', redirect: '/404', hidden: true
    },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
