<template>
  <a-form ref="formRef" :model="model" id="home" :rules="rules">
    <a-row>
      <a-col :span="4"></a-col>
      <a-col :span="16">
        <a-form-item label="昵称" name="name">
          <a-input size="large" v-model:value="model.name" placeholder="请输入昵称">
          </a-input>
        </a-form-item>
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="4"></a-col>
      <a-col :span="16">
        <a-form-item label="手机号" name="mobile">
          <a-input size="large" v-model:value="model.mobile" placeholder="请输入联系方式（手机号码）">
          </a-input>
        </a-form-item>
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="24">
        <a-form-item>
          <a-button type="primary" @click="onCheck" size="large" danger>提交</a-button>
        </a-form-item>
      </a-col>
    </a-row>
  </a-form>
  <div class="image-gallery">
    <a-image v-for="(image, index) in images" :key="index" :src="image.src" alt="image"/>
  </div>
  <div class="desc">
    备案号：鄂ICP备19015227号-1
  </div>
</template>

<script>
// @ is an alias to /src
import {defineComponent, getCurrentInstance, reactive, ref} from 'vue';
import {useRouter} from "vue-router";

export default defineComponent({
  setup(props) {
    const router = useRouter();
    const {proxy} = getCurrentInstance();
    const formRef = ref();
    const model = reactive({
      name: '',
      mobile: ''
    });
    const rules = {
      name: {required: true,message: "请输入昵称！"},
      mobile: {required: true,
        pattern: new RegExp(
            /^1[0-9]{10}$/
        ),  //写入正则效验
        message: "请输入手机号码！"
      }
    };

    const onCheck = () => {
          // 表单验证  validate触发表单验证，验证不通过会阻断后续操作
          formRef.value.validate()
              .then(() => {
                const formData = formRef.value.validateFields();
                formData.shortLink = props.shortLink;
                formData.name = model.name;
                formData.mobile = model.mobile;
                // console.log(formData);
                proxy.$http({url: '/api/user/add',
                          method: 'POST',
                          params: formData})
                    .then(res => {
                      if (res.data.success) {
                        router.push({path: 'adr', query: {"userId": res.data.message}});
                      }
                    })
              })
              .catch((error) => {
                console.log("error", error);
              });
    };

    return {
      model,
      onCheck,
      formRef,
      rules,
      images: [
        { src: './taocan.jpg' },
        { src: './info.jpg' },
      ]
    };
  },
  props: ['shortLink'],
  name: 'HomeView',
  created() {
    this.$http({url:"/api/updateClickLink",method:'POST',  params:{"shortLink": this.shortLink}}).then((res) => {
      console.log(res.data);
      if (res.data.success) {
        // console.log("记录");
      }
    }).catch(err => {
      console.log("提交异常");
    })

  },
  methods: {
  }
})
</script>
<style lang="less" scoped>

.image-gallery img {
  width: 100%;
  margin-bottom: 10px;
}

.ant-form {
  color: #f6fff7;
  font-size: 18px;
}

h2 {
  margin-top: 10%;
  color: #0d5db7;
}

.desc {
  text-align: center;
  color: #fff;
}

.ant-list {
  color: #999999;
  text-align: left;
}
</style>
