<template>
<!--  <nav>-->
<!--    <router-link to="/"></router-link>-->
<!--  </nav>-->
  <router-view/>
</template>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  //color: #459cfd;
  background-color: #459cfd;
}

nav {
  //margin-top: 30px;
  a {
    font-weight: bold;
    color: #f6fff7;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

//}
</style>
